<template>
  <div class="loader">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="spinner animate__animated animate__repeat-3 animate__pulse lazy">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPreloader",
};
</script>