<template>
  <div>
    <PrivacyPolicy />
  </div>
</template>

<script>
// @ is an alias to /src
import PrivacyPolicy from "../components/Legals/PrivacyPolicy.vue";

export default {
  name: "PrivPol",
  components: {
    PrivacyPolicy,
  },
};
</script>
