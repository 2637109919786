<template>
  <div>
    <div id="Overview" class="overview-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12">
            <div class="legal-title three-section-title">
              <span class="sub-title">Digital Touch Point Playcourt</span>
              <h2>Kebijakan Privasi</h2>
            </div>
            <div class="col-sm-12 col-lg-12 text-area">
              <div class="text-item text">
                <p>
                  <strong>DTP Playcourt</strong> dan <strong>TELKOM</strong> menyadari bahwa informasi Pengguna bersifat rahasia dan pribadi, oleh karena itu Kami berkomitmen untuk melindungi dan merahasiakan data Pengguna sesuai dengan peraturan perundang-undangan yang berlaku.
                </p>
                <p>
                  Dengan mendaftar dan/atau menggunakan situs playcourt.id, aka Pengguna dianggap telah membaca, mengerti, memahami, dan menyetujui semua isi Kebijakan Privasi ini dan Pengguna memberikan persetujuan kepada <strong>DTP Playcourt</strong> untuk memperoleh, mengumpulkan, mengolah, menganalisis, menampilkan, mengirimkan, membuka, menyimpan, mengubah, menghapus, mengelola dan/atau mempergunakan data tersebut untuk tujuan sebagaimana tercantum dalam Kebijakan Privasi.
                </p>
                <p>
                  <ol>
                    <li><strong>Perolehan dan Pengumpulan Data Pribadi </strong>
                      <ol type="a">
                        <li>Perolehan dan pengumpulan data pribadi yang dilakukan oleh <strong>DTP Playcourt</strong> dibatasi pada informasi yang relevan dan sesuai dengan tujuannya, yang meliputi:
                          <ol type="i">
                            <li>Nama Lengkap</li>
                            <li>Alamat Email</li>
                            <li>Nomor Telepon</li>
                          </ol>
                        </li>
                        <li>
                          Selain dari Data Pribadi yang dimaksud pada Poin 1a, <strong>DTP Playcourt</strong>  dapat meminta Data Pribadi Lainnya di kemudian hari yang sesuai dengan kebutuhan. 
                        </li>
                        <li>
                          Dengan memberikan Data Pribadi sebagaimana Poin 1a di atas pada saat melakukan Registrasi pada situs, Pengguna menyetujui pemberian data untuk digunakan oleh <strong>TELKOM</strong> dalam menyelenggarakan Layanan <strong>DTP Playcourt</strong>. 
                        </li>
                        <li>
                          Data Pribadi yang diberikan merupakan Data Pengguna yang benar. Apabila Pengguna memberikan pernyataan dan jaminan, informasi atau data pribadi yang tidak benar, tidak jelas, tidak akurat, atau tidak lengkap, maka  <strong>DTP Playcourt</strong> berhak menolak permohonan pembuatan Akun  <strong>DTP Playcourt</strong> dan menangguhkan atau memberhentikan sebagian atau seluruh Layanan  <strong>DTP Playcourt</strong> yang diberikan kepada Pengguna. Pengguna bertanggung jawab sepenuhnya atas Data yang diberikan dan melepaskan <strong>TELKOM</strong> dari seluruh konsekuensi yang timbul.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Pengolahan dan Penganalisaan Data Pribadi</strong>
                      <p>
                        Data Pengguna akan digunakan dalam rangka penyelenggaraan Layanan <strong>DTP Playcourt</strong>, baik untuk saat ini maupun untuk pengembangan Layanan di kemudian hari.
                      </p>
                    </li>
                    <li>
                      <strong>Penyimpanan Data Pribadi</strong>
                      <ol type="a">
                        <li>Data Pribadi Pengguna yang disimpan dalam <strong>DTP Playcourt</strong> telah melalui proses verifikasi dan terenkripsi selama Layanan beroperasi.</li>
                        <li>Apabila Layanan <strong>DTP Playcourt</strong> sudah tidak beroperasi, <strong>DTP Playcourt</strong> berhak menyimpan dan menggunakan Data Pribadi Pengguna sampai paling lama 5 (lima) tahun.</li>
                        <li><strong>DTP Playcourt</strong> menggunakan database dengan infrastruktur & server Pihak Ketiga (third party), cloud, atau semacamnya untuk menampung perolehan dan pengumpulan data pribadi, serta menggunakan perangkat lunak yang legal.</li>
                      </ol>
                    </li>
                    <li>
                      <strong>Penggunaan Data Email oleh Pihak Ketiga (Twilio SendGrid)</strong>
                      <p>Untuk memfasilitasi pengiriman email yang efisien dan tepat waktu, <strong>DTP Playcourt</strong> bekerja sama dengan Twilio SendGrid, layanan pihak ketiga yang akan memproses dan mengirimkan email kepada Pengguna.</p>
                      <ol type="a">
                        <li>
                          <strong>Kebijakan Privasi Twilio SendGrid</strong>
                          <p>Twilio SendGrid akan memproses data email Pengguna sesuai dengan kebijakan privasi mereka. Informasi lebih lanjut mengenai kebijakan privasi Twilio SendGrid dapat ditemukan di [kebijakan privasi Twilio SendGrid].</p>
                        </li>
                        <li>
                          <strong>Keamanan dan Penggunaan Data</strong>
                          <p>Data email Pengguna akan disimpan dengan standar keamanan tinggi oleh Twilio SendGrid dan hanya akan digunakan untuk tujuan pengiriman informasi yang relevan dengan Layanan <strong>DTP Playcourt</strong>.</p>
                        </li>
                        <li>
                          <strong>Persetujuan Pengguna</strong>
                          Dengan memberikan alamat email kepada <strong>DTP Playcourt</strong>, Pengguna menyetujui penggunaan data email mereka oleh Twilio SendGrid untuk tujuan pengiriman informasi melalui email. 
                        </li>
                        <li>
                          <strong>Hak Pengguna</strong>
                          <p>Pengguna berhak meminta penghapusan atau perubahan data email mereka kapan saja. Silakan hubungi kami di [kontak perusahaan] untuk permintaan tersebut.</p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Keamanan dan Privasi Data Pribadi</strong>
                      <ol type="a">
                        <li><strong>Keamanan</strong>
                          <ol type="i">
                            <li>Kami menjaga tindakan teknis dan organisasi, kontrol internal, dan rutinitas keamanan data yang dimaksudkan untuk melindungi Data Pribadi Pengguna dari kehilangan atau perubahan yang tidak disengaja, pengungkapan atau akses yang tidak sah, atau perusakan yang melanggar hukum.</li>
                            <li>Pengguna bertanggung jawab terhadap keamanan Data Pribadi yang digunakan untuk mengoperasikan Layanan termasuk di dalamnya password, kode OTP, atau kode keamanan (security code) lainnya untuk mencegah penyalahgunaan pihak lain.</li>
                            <li>Walaupun <strong>DTP Playcourt</strong> telah menggunakan upaya terbaiknya untuk menjaga dan melindungi Data Pribadi Pengguna dan/atau Pelanggan, perlu diketahui bahwa pengiriman data melalui Internet tidak pernah sepenuhnya aman. Dengan demikian, <strong>DTP Playcourt</strong> tidak dapat menjamin sepenuhnya keamanan data yang telah dikirimkan Pengguna kepada <strong>DTP Playcourt</strong>. Namun, dalam hal terjadi kegagalan terhadap perlindungan Data Pribadi tersebut, <strong>DTP Playcourt</strong> akan memberitahukan secara tertulis kepada Pengguna sesuai peraturan perundang-undangan yang berlaku.</li>
                          </ol>
                        </li>
                        <li><strong>Privasi</strong>
                          <p>Data Pribadi Pengguna akan digunakan hanya untuk keperluan penyelenggaraan Layanan <strong>DTP Playcourt</strong> dan tidak akan diserahkan, ditampilkan, diungkapkan kepada pihak ketiga tanpa persetujuan Pengguna, kecuali terdapat permintaan data dari Aparat Penegak Hukum atau Pejabat yang berwenang lainnya maupun karena diwajibkan oleh peraturan perundang-undangan.</p>
                        </li>
                      </ol>
                    </li>
                    <li><strong>Penggunaan Data Pribadi</strong>
                      <ol type="a">
                        <li>Kami hanya akan menggunakan Data Pribadi Pengguna untuk dapat menghubungi Pengguna sewaktu-waktu jika diperlukan serta untuk kepentingan peningkatan Layanan.</li>
                        <li>Kami tidak akan menggunakan Data Pribadi Pengguna atau memperoleh informasi darinya untuk keperluan iklan atau tujuan komersial lainnya tanpa persetujuan Pengguna dan/atau Pelanggan.</li>
                      </ol>
                    </li>
                    <li><strong>Perubahan dan Pembaruan Data Pribadi</strong>
                      <ol type="a">
                        <li>Pengguna menyatakan bersedia dan menjamin untuk senantiasa memperbaharui Data Pribadi melalui situs playcourt.id apabila ada perubahan data yang terkait dengan Pengguna tanpa mengganggu pengelolaan Data Pribadi.</li>
                        <li><strong>DTP Playcourt</strong> <em>dapat sewaktu-waktu melakukan perubahan atau pembaruan</em> terhadap Kebijakan Privasi ini. Kami menyarankan agar Pengguna membaca secara seksama dan memeriksa halaman Kebijakan Privasi ini secara berkala, untuk mengetahui segala perubahan. Dengan tetap mengakses dan menggunakan layanan <strong>DTP Playcourt</strong>, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi ini.  </li>
                      </ol>
                    </li>
                    <li><strong>Penampilan, Pengumuman, Transfer, Penyebarluasan, atau Pengungkapan <strong>DTP Playcourt</strong> </strong>
                      <p>Dengan ini menjamin bahwa <strong>DTP Playcourt</strong> hanya akan menampilkan, mengumumkan, mengirimkan, menyebarluaskan, dan/atau membuka akses Data Pribadi Pengguna dalam sistem berdasarkan keadaan seperti berikut:</p>
                      <ol type="a">
                        <li>Atas Persetujuan Pengguna kecuali ditentukan lain oleh ketentuan perundang-undangan;</li>
                        <li>Telah dilakukan verifikasi keakuratan dan kesesuaian dengan tujuan perolehan dan pengumpulan Data Pribadi tersebut; dan</li>
                        <li>Untuk keperluan proses penegakan hukum, di mana penyelenggara sistem elektronik wajib memberikan Data Pribadi yang terdapat dalam sistem elektronik atau DataPribadi yang dihasilkan oleh sistem elektronik atas permintaan yang sah dari aparat penegak hukum berdasarkan ketentuan peraturan perundang-undangan.</li>
                      </ol>
                    </li>
                      <li><strong>Penghapusan atau Pemusnahan Data Pribadi</strong>
                        <p>Pemusnahan Data Pribadi dalam  <strong>DTP Playcourt</strong> hanya dapat dilakukan jika:</p>
                        <ol type="a">
                          <li>Data Pribadi Pengguna yang berada dibawah kendali <strong>DTP Playcourt</strong> tidak lagi relevan untuk memenuhi tujuan dari pengumpulannya; </li>
                          <li>Telah melewati ketentuan jangka waktu penyimpanan Data Pribadi dalam sistem elektronik berdasarkan peraturan perundang-undangan atau sesuai dengan ketentuan peraturan perundang-undangan lainnya yang secara khusus mengatur di masing-masing instansi pengawas dan pengatur sektor untuk itu; atau</li>
                          <li>Dengan mengesampingkan Poin 3b, Pengguna dapat mengajukan Permohonan Penghapusan Data Pribadi apabila Pengguna tidak lagi menggunakan Layanan <strong>DTP Playcourt</strong>.</li>
                        </ol>
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "priv-pol",
};
</script>

<style>
.margin-top {
  margin-top: 70px;
}
,
.margin-bottom {
  margin-bottom: 25px;
}
.text {
  text-align: justify;
}
</style>
