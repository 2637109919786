<template>
  <div>
    <Termcondition />
  </div>
</template>
<script>
// @ is an alias to /src
import Termcondition from "../components/Legals/term-cond.vue";

export default {
  name: "TermConditions",
  components: {
    Termcondition,
  },
};
</script>
