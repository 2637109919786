<template>
  <div id="app">
    <Preloader v-if="isLoading" />
    <Navbar />
    <router-view> </router-view>
    <Footer />
    <BackToTop />
  </div>
</template>

<script>
import Preloader from "./components/Layouts/Preloader";
import Navbar from "./components/Layouts/Navbar";
import Footer from "./components/Layouts/Footer";

import BackToTop from "./components/Layouts/BackToTop";

export default {
  name: "App",
  components: {
    Preloader,
    Navbar,
    Footer,
    BackToTop,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>
