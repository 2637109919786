<template>
  <footer class="footer-style-area pt-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-6 col-lg-3">
          <div class="single-footer-widget">
            <div class="footer-logo">
              <a :href="'#'">
                <img src="../../assets/img/logo2.png" alt="Logo" />
              </a>
              <p>
                PlayCourt is a Platform as a Service (PaaS) serving as the
                Digital Infrastructure at the Digital Service Division of PT
                Telekomunikasi Indonesia.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-2">
          <div class="single-footer-widget ps-3">
            <h3>PlayCourt</h3>
            <ul class="quick-link">
              <li>
                <a
                  v-if="isMain"
                  @click="scroll('Overview')"
                  href="javascript:void(0)"
                >
                  Overview
                </a>
                <a v-else @click="home('Overview')" class="nav-link">
                  Overview
                </a>
              </li>
              <li>
                <a
                  v-if="isMain"
                  @click="scroll('Features')"
                  href="javascript:void(0)"
                >
                  Features
                </a>
                <a v-else @click="home('Features')" class="nav-link">
                  Features
                </a>
              </li>
              <li>
                <a
                  v-if="isMain"
                  @click="scroll('Steps')"
                  href="javascript:void(0)"
                >
                  How To
                </a>
                <a v-else @click="home('Steps')" class="nav-link"> How To </a>
              </li>
              <li>
                <a
                  v-if="isMain"
                  @click="scroll('Order')"
                  href="javascript:void(0)"
                >
                  Order
                </a>
                <a v-else @click="home('Order')" class="nav-link"> Order </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="single-footer-widget ps-3">
            <h3>Legal</h3>
            <ul class="quick-link">
              <li>
                <a id="tnc" @click="termcondition"> Terms & Conditions </a>
              </li>
              <li>
                <a id="pp" @click="privpolicy"> Privacy Policy </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="single-footer-widget">
            <h3>Contact Us</h3>
            <ul class="address-info">
              <li>
                <i class="bi-whatsapp"></i>
                <a :href="'http://wa.me/628111033882'">+62811 1033 882</a>
              </li>
              <li>
                <i class="bi-book"></i>
                <a :href="'mailto:info@playcourt.id'"> info@playcourt.id </a>
              </li>
              <li>
                <i class="bi-geo-alt"></i>
                <a :href="'https://goo.gl/maps/ueSL6AgzaXVetdw8A'">
                  Jl. Gegerkalong Hilir No.47 Kota Bandung, Jawa Barat 40152
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright-style-area">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <div class="copyright-item">
              <p>
                &copy; Copyright {{ currentYear }} By <a href="#">PlayCourt</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    });
  },
  computed: {
    isMain() {
      return this.$router.name === "home";
    },
  },
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      if (element) {
        element.scrollIntoView({ top: element.offsetTop, behavior: "smooth" });
      }
    },
    home(refName) {
      this.$router.push({ name: "home" }).then(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            const element = document.getElementById(refName);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 1000); // delay of 500ms
        });
      });
    },
    termcondition() {
      this.$router.push("/termconditions");
    },
    privpolicy() {
      this.$router.push("/privacypolicy");
    },
  },
};
</script>
