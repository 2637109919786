<template>
  <div>
    <div id="Overview" class="overview-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12">
            <div class="overview-content text-center">
              <img class="margin-top" src="../assets/img/logo.png" height="150" alt="logo"/>
              <br/><br/><br/>
              <!-- <h4>Reliable cloud computing platform</h4> -->
              <!-- <h1>Easily Deploy And Scale Your Apps</h1> -->
              <p>
                PlayCourt is a comprehensive application development platform <br/>
                specifically designed for the development of Telkom Indonesia's digital products.
              </p>
              <br/>
              <ul class="overview-btn">
                <li>
                  <a href="https://dashboard.playcourt.id/login" class="main-default-btn">Order Now</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppOverview",
};
</script>

<style>
.margin-top {
  margin-top: 70px;
}
</style>