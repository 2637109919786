<template>
  <div>
    <Overview />
    <features />
    <steps />
    <Order />
  </div>
</template>
<script>
// @ is an alias to /src
import Overview from "../components/Overview.vue";
import Features from "../components/Features.vue";
import Steps from "../components/Steps.vue";
import Order from "../components/Order.vue";

export default {
  name: "HomeView",
  components: {
    Overview,
    Features,
    Steps,
    Order,
  },
};
</script>
