<template>
  <div>
    <div id="Overview" class="overview-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-12">
            <!-- <div class="overview-content"> -->
            <div class="legal-title three-section-title">
              <span class="sub-title">Digital Touch Point Playcourt</span>
              <h2>Syarat dan Ketentuan</h2>
            </div>
            <div class="col-sm-12 col-lg-12 text-area">
              <div class="text-item text">
                <p>
                  <strong>Digital Touch Point Playcourt</strong>
                  (selanjutnya disebut “DTP Playcourt”) merupakan platform
                  digital yang berfungsi sebagai digital touchpoint (DTP) untuk
                  Produk Owner melakukan request penggunaan project di Cluster
                  INS Playcourt yang dikelola oleh PT Telekomunikasi Indonesia
                  Tbk (selanjutnya disebut “TELKOM”).
                </p>
                <p>
                  Saat menggunakan DTP Playcourt, Pengguna (selanjutnya disebut
                  “Anda” atau “Pengguna”) tunduk pada pedoman, aturan, atau
                  ketentuan yang berlaku, yang dapat diperbarui dari waktu ke
                  waktu (termasuk dan tidak terbatas pada Kebijakan Privasi DTP
                  Playcourt).
                </p>
                <p>
                  Dengan mendaftar dan/atau menggunakan Situs dan/atau Aplikasi
                  maka Anda dianggap telah membaca, mengerti, memahami, dan
                  menyetujui semua isi Syarat dan Ketentuan ini. Jika Anda tidak
                  menerima dan menyetujui salah satu, sebagian, atau seluruh isi
                  Syarat dan Ketentuan ini, maka Anda tidak dapat menggunakan
                  DTP Playcourt.
                </p>
                <!-- <p> -->
                <ol>
                  <li>
                    <strong>Definisi</strong><br />
                    Istilah yang dimuat dalam Syarat dan Ketentuan ini merujuk
                    kepada definisi sebagai berikut:
                    <ol type="a">
                      <li>
                        <strong>Akun</strong> adalah akun yang telah didaftarkan
                        oleh Pengguna pada DTP Playcourt.
                      </li>
                      <li>
                        <strong>Data Pribadi</strong> adalah setiap informasi
                        yang benar dan nyata yang melekat dan dapat
                        diidentifikasi pada masing-masing individu , baik secara
                        langsung maupun tidak langsung, baik berdasarkan salah
                        satu maupun gabungan darinya, baik melalui sistem
                        elektronik maupun non elektronik. Informasi yang
                        dimaksud termasuk namun tidak terbatas pada nama
                        seseorang, nomor identitas, nomor handphone, lokasi,
                        identitas dalam jaringan sistem elektronik, dan hal-hal
                        lain yang berkaitan dengan individu tersebut.
                      </li>
                      <li>
                        <strong>Data Profil</strong> adalah data yang terdapat
                        pada halaman profil bagi Pengguna.
                      </li>
                      <li>
                        <strong>Layanan</strong>adalah platform digital yang
                        berfungsi sebagai digital touchpoint (DTP) untuk Produk
                        Owner melakukan request penggunaan project di Cluster
                        INS Playcourt.
                      </li>
                      <li>
                        <strong>Pengguna</strong> adalah platform digital yang
                        berfungsi sebagai digital touchpoint (DTP) untuk Produk
                        Owner melakukan request penggunaan project di Cluster
                        INS Playcourt.
                      </li>
                      <li>
                        <strong>Situs</strong> adalah
                        <a href="https://playcourt.id/">playcourt.id</a> dan
                        <a href="https://dashboard.playcourt.id/login"
                          >dashboard.playcourt.id</a
                        >.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong> Akun dan Perilaku </strong>
                    <ol type="a">
                      <li>
                        Hanya Pengguna yang memiliki Akun yang dapat menggunakan
                        layanan DTP Playcourt.
                      </li>
                      <li>
                        Untuk membuat Akun DTP Playcourt, Anda dapat diminta
                        untuk memberikan beberapa informasi dan Data Pribadi
                        Anda. Sehubungan dengan pembuatan Akun DTP Playcourt dan
                        penggunaan Layanan DTP Playcourt, Anda setuju untuk
                        memberikan informasi dan data pribadi Anda secara benar,
                        jelas, akurat, dan lengkap kepada DTP Playcourt ketika
                        diminta, sekurang-kurangnya berupa:
                        <ol type="i">
                          <li>Nama lengkap</li>
                          <li>Alamat email</li>
                          <li>Nomor telepon</li>
                        </ol>
                      </li>
                      <li>
                        Pengguna bertanggung jawab untuk menjaga kerahasiaan dan
                        keamanan Akun dan password Pengguna.
                      </li>
                      <li>
                        Harap diperhatikan bahwa DTP Playcourt disediakan secara
                        eksklusif untuk pengguna internal TELKOM dan tidak
                        ditujukan untuk penggunaan umum. Dengan menggunakan
                        aplikasi ini, Anda menyetujui untuk hanya mengaksesnya
                        dalam lingkup kerja dan kepentingan perusahaan yang
                        sesuai. Apabila Pengguna memberikan pernyataan dan
                        jaminan, informasi atau Data Pribadi yang tidak benar,
                        tidak jelas, tidak akurat, atau tidak lengkap, maka DTP
                        Playcourt berhak menolak permohonan pembuatan Akun dan
                        menangguhkan atau memberhentikan sebagian atau seluruh
                        akses dan Layanan DTP Playcourt yang diberikan kepada
                        Pengguna.
                      </li>
                      <li>
                        DTP Playcourt berhak menganggap dan memperlakukan
                        seluruh aktivitas yang dilakukan melalui Akun DTP
                        Playcourt sebagai aktivitas yang telah dilakukan oleh
                        Pengguna.
                      </li>
                      <li>
                        Pengguna bertanggung jawab atas segala kerugian dan
                        akibat hukum yang timbul dari kesalahan atau kelalaian
                        Pengguna dalam menjaga kerahasiaan password dan akun
                        Pengguna.
                      </li>
                      <li>
                        Pengguna menyetujui untuk segera memberitahukan DTP
                        Playcourt mengenai setiap dugaan atau aktivitas
                        penggunaan Akun DTP Playcourt atau password secara tidak
                        berwenang atau pelanggaran keamanan lainnya yang
                        berkaitan dengan Akun Pengguna secepat mungkin.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Hak Kekayaan Intelektual</strong>
                    <p>
                      Hak Atas Kekayaan Intelektual terkait dengan DTP Playcourt
                      dan Layanan, termasuk namun tidak terbatas pada, nama,
                      logo, kode program, desain, merek dagang, teknologi, basis
                      data, proses dan model bisnis, dilindungi oleh hak cipta,
                      merek, paten dan hak kekayaan intelektual lainnya yang
                      tersedia berdasarkan hukum Republik Indonesia yang
                      terdaftar atas nama TELKOM Tidak ada hak atau izin yang
                      diberikan baik secara langsung atau tidak langsung kepada
                      Pengguna atau pihak mana pun yang mengakses Situs untuk
                      menggunakan dan/atau memperbanyak Kekayaan Intelektual,
                      dan tidak ada pihak yang dapat mengklaim hak atas,
                      kepemilikan atau kepentingan apapun di dalamnya. Anda
                      tidak diperbolehkan untuk mengubah salinan dalam bentuk
                      kertas maupun digital dari materi apapun. Apabila Anda
                      menemukan terdapat pelanggaran hak kekayaan intelektual,
                      dapat melaporkan ke DTP Playcourt dengan menghubungi
                      kontak yang tersedia.
                    </p>
                  </li>
                  <li>
                    <strong>Larangan dan Batasan Tanggung jawab</strong>
                    <p>
                      Tidak ada seorangpun yang diperbolehkan melakukan upaya
                      dan/atau yang dapat dianggap upaya untuk mengumpulkan,
                      mengolah dan/atau mengungkapkan data yang terkandung dalam
                      sistem elektronik DTP Playcourt untuk tujuan yang
                      melanggar peraturan perundang-undangan.
                    </p>
                    <p>
                      Tidak ada seorangpun yang diperbolehkan menggunakan situs
                      DTP Playcourt untuk melanggar keamanan atau integritas
                      jaringan, komputer atau sistem komunikasi apa pun,
                      aplikasi perangkat lunak, atau jaringan atau perangkat
                      komputasi (masing-masing, "Sistem").
                    </p>
                    <p>
                      Kegiatan yang dilarang termasuk mengakses atau menggunakan
                      Sistem apa pun tanpa izin, termasuk mencoba menyelidiki,
                      memindai, atau menguji kerentanan Sistem atau untuk
                      melanggar segala tindakan keamanan atau otentikasi yang
                      digunakan oleh suatu Sistem.
                    </p>
                    <p>
                      DTP Playcourt dan TELKOM tidak bertanggung jawab atas
                      pengumpulan, pengolahan dan/atau pengungkapan yang
                      dilakukan di luar sistem elektronik DTP Playcourt.
                    </p>
                  </li>
                  <li>
                    <strong
                      >Jaminan TELKOM dan Pembatasan Tanggung Jawab TELKOM
                      secara Umum</strong
                    >
                    <ol type="a">
                      <li>
                        Pengguna setuju bahwa Pengguna memanfaatkan DTP
                        Playcourt atas keinginan sendiri dan Layanan DTP
                        Playcourt diberikan kepada Pengguna pada "SEBAGAIMANA
                        ADANYA" dan "SEBAGAIMANA TERSEDIA".
                      </li>
                      <li>
                        Sejauh diizinkan oleh hukum yang berlaku,
                        <strong>TELKOM</strong> (termasuk TELKOM Group,
                        direktur, dan karyawan) tidak bertanggung jawab, dan
                        Pengguna setuju untuk tidak menuntut
                        <strong>TELKOM</strong> bertanggung jawab, atas segala
                        kerusakan atau kerugian (termasuk namun tidak terbatas
                        pada hilangnya uang, reputasi, keuntungan, atau kerugian
                        tak berwujud lainnya) yang diakibatkan secara langsung
                        atau tidak langsung dari:
                        <ol type="i">
                          <li>
                            Penggunaan atau ketidakmampuan Pengguna dalam
                            menggunakan Layanan DTP Playcourt;
                          </li>
                          <li>Keterlambatan atau gangguan dalam Aplikasi;</li>
                          <li>
                            Kelalaian dan kerugian yang ditimbulkan oleh
                            masing-masing Pengguna dan/atau Pelanggan;
                          </li>
                          <li>Pelanggaran Hak atas Kekayaan Intelektual;</li>
                          <li>
                            Perselisihan antar Pengguna dan/atau Pelanggan;
                          </li>
                          <li>Pencemaran nama baik pihak lain;</li>
                          <li>
                            Kerugian akibat pembayaran tidak resmi kepada pihak
                            lain dengan cara apa pun mengatas-namakan TELKOM
                            ataupun kelalaian penulisan rekening dan/atau
                            informasi lainnya dan/atau kelalaian pihak bank;
                          </li>
                          <li>
                            Virus atau perangkat lunak berbahaya lainnya (bot,
                            script, automation tool, hacking tool) yang
                            diperoleh dengan mengakses, atau menghubungkan ke
                            Layanan DTP Playcourt;
                          </li>
                          <li>
                            Gangguan, bug, kesalahan atau ketidakakuratan apapun
                            dalam Layanan DTP Playcourt;
                          </li>
                          <li>
                            Kerusakan pada perangkat keras Pengguna dari
                            penggunaan setiap Layanan DTP Playcourt;
                          </li>
                          <li>
                            Adanya tindakan peretasan yang dilakukan oleh pihak
                            ketiga kepada akun Pengguna dan/ Pelanggan;
                          </li>
                          <li>Force Majeure.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Kritik dan/ atau Saran</strong>
                    <ol type="a">
                      <li>
                        Dalam hal terdapat pertanyaan, keluhan dan/ atau
                        pengaduan sehubungan dengan penggunaan DTP Playcourt,
                        maka Pengguna dapat mengajukan pertanyaan, keluhan
                        dan/atau pengaduan dengan melampirkan identitas Pengguna
                        ke:
                        <ol type="i">
                          <li>
                            Secara tertulis melalui:
                            <a href="mailto:support@playcourt.id"
                              >support@playcourt.id</a
                            >
                            atau WhatsApp ke
                            <a :href="'http://wa.me/628111033882'"
                              >+62811 1033 882</a
                            >
                          </li>
                          <li>
                            Langsung ke alamat lengkap:
                            <a :href="'https://goo.gl/maps/ueSL6AgzaXVetdw8A'">
                              Bandung Indigo Hub, Jl. Gegerkalong Hilir No.47
                              Kota Bandung, Jawa Barat 40152
                            </a>
                          </li>
                        </ol>
                      </li>
                      <li>
                        Dalam hal adanya penambahan, pengurangan dan/atau
                        perubahan channel pengaduan akan diinformasikan kemudian
                        melalui Situs.
                      </li>
                      <li>
                        <strong>DTP Playcourt</strong> akan melakukan verifikasi
                        data Pengguna dengan berpedoman pada data Pengguna yang
                        tersimpan pada sistem <strong>DTP Playcourt</strong>.
                        <strong>DTP Playcourt</strong> berhak melakukan
                        penolakan dalam memproses pertanyaan, keluhan dan/atau
                        pengaduan yang diajukan Pengguna dalam hal data Pengguna
                        yang diverifikasi tidak sesuai dengan data Pengguna yang
                        tersimpan pada sistem <strong>DTP Playcourt</strong>.
                      </li>
                      <li>
                        <strong>DTP Playcourt</strong> akan melakukan
                        pemeriksaan dan/ atau investigasi atas pengaduan
                        Pengguna <strong>DTP Playcourt</strong> serta memberikan
                        jawaban kepada Pengguna sesuai dengan kebijakan dan
                        prosedur yang berlaku di
                        <strong>DTP Playcourt</strong> setelah
                        <strong>DTP Playcourt</strong> menerima
                        keluhan/pengaduan secara lengkap dalam kurun waktu 2
                        (dua) minggu.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong><em>Force Majeure</em></strong>
                    <ol type="a">
                      <li>
                        <strong>DTP Playcourt</strong> dan
                        <strong>TELKOM</strong> tidak dapat diminta
                        pertanggungjawaban untuk keterlambatan dan/atau
                        kegagalan dalam memenuhi kewajiban yang disebabkan oleh
                        kejadian-kejadian di luar kemampuan
                        <strong>TELKOM</strong> (selanjutnya disebut Force
                        Majeure). Kejadian-kejadian yang dapat dikategorikan
                        Force Majeure adalah termasuk segala keadaan atau
                        peristiwa yang terjadi di luar kekuasaan Para Pihak,
                        termasuk akan tetapi tidak terbatas pada huru-hara,
                        epidemi, kebakaran, banjir, gempa bumi, pemogokan umum,
                        perang, sabotase, dan berlaku efektifnya suatu ketentuan
                        Perundang-undangan Republik Indonesia yang membatasi
                        maupun mengakibatkan masing-masing pihak tidak dapat
                        melaksanakan kewajibannya menurut Syarat dan Ketentuan
                        ini.
                      </li>
                      <li>
                        Dalam hal terjadinya salah satu atau beberapa kejadian
                        dan/atau peristiwa sebagaimana dimaksud,
                        <strong>DTP Playcourt</strong> dan
                        <strong>TELKOM</strong> berkewajiban untuk
                        memberitahukan secara tertulis melalui email, Situs,
                        dan/ atau Aplikasi kepada Pengguna selambat-lambatnya 7
                        (tujuh) hari kalender sejak terjadinya peristiwa
                        tersebut.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Penyelesaian Perselisihan </strong>
                    <ol type="a">
                      <li>
                        Para Pihak sepakat untuk menyelesaikan perselisihan
                        dalam pelaksanaan Kontrak Berlangganan ini secara
                        musyawarah dan mufakat.
                      </li>
                      <li>
                        Apabila musyawarah dan mufakat tidak tercapai dalam
                        waktu 30 (tiga puluh) hari kalender atau suatu jangka
                        waktu lainnya sebagaimana disepakati Para Pihak
                        terhitung sejak timbulnya Perselisihan, maka Para Pihak
                        sepakat bahwa penyelesaian Perselisihan tersebut akan
                        diteruskan ke BANI (Badan Arbitrase Nasional Indonesia)
                        yang bertempat di Jakarta dan menyetujui keputusan BANI
                        tersebut bersifat final, sehingga tidak dapat dimintakan
                        putusan pada tingkat yang lebih tinggi dan mengikat Para
                        Pihak.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Hukum yang Berlaku</strong>
                    <p>
                      Syarat dan Ketentuan ini tunduk pada Hukum Negara Republik
                      Indonesia, hal-hal yang tidak atau belum diatur dalam
                      Syarat dan Ketentuan ini tunduk pada ketentuan hukum yang
                      berlaku bagi Perjanjian, termasuk namun tidak hanya
                      terbatas pada Hukum Perjanjian yang termuat dalam Buku
                      III, Kitab Undang-Undang Hukum Perdata (KUHPerdata).
                    </p>
                  </li>
                  <li>
                    <strong>Ketentuan Penutup</strong>
                    <p>
                      Ketentuan ini mulai berlaku pada saat ditetapkan oleh
                      Pihak
                      <strong>DTP Playcourt</strong> pada tanggal [01/06/2024]
                    </p>
                  </li>
                </ol>
                <!-- </p> -->
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "term-cond",
};
</script>

<style>
.margin-top {
  margin-top: 70px;
}
,
.margin-bottom {
  margin-bottom: 25px;
}
.text {
  text-align: justify;
}
</style>
